<template>
  <b-row>
    <b-col
      v-if="!isUpdate"
      md="12"
    >
      <b-card no-body>
        <stock-search />
      </b-card>
    </b-col>
    <b-col md="6">
      <b-card title="Sözleşme">
        <validation-observer ref="simpleRules">
          <contract-no />
          <start-date />
          <end-date />
          <contract-date />
          <contract-file />
          <customer-files />
          <upload-bond-file />
          <contract-statuses v-if="isUpdate" />
          <stock-statuses v-if="isUpdate" />
          <notes />
          <save-button
            :save-action="submitForm"
            :is-disabled="!dataItem.id_com_rental_stocks.length"
          />
        </validation-observer>
      </b-card>
    </b-col>
    <b-col md="6">
      <b-card
        no-body
      >
        <b-card-header>
          <b-card-title>Sözleşme Aracı</b-card-title>
        </b-card-header>
        <selected-stocks v-if="!isUpdate" />
        <rental-stock v-else />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import StockSearch from '@/views/Rental/Contracts/elements/StockSearch.vue'
import SelectedStocks from '@/views/Rental/Contracts/elements/SelectedStocks.vue'
import RentalStock from '@/views/Rental/Contracts/elements/RentalStock.vue'
import ContractNo from '@/views/Rental/Contracts/elements/ContractNo.vue'
import StartDate from '@/views/Rental/Contracts/elements/StartDate.vue'
import EndDate from '@/views/Rental/Contracts/elements/EndDate.vue'
import ContractDate from '@/views/Rental/Contracts/elements/ContractDate.vue'
import ContractFile from '@/views/Rental/Contracts/elements/ContractFile.vue'
import CustomerFiles from '@/views/Rental/Contracts/elements/CustomerFiles.vue'
import Notes from '@/views/Rental/Contracts/elements/Notes.vue'
import ContractStatuses from '@/views/Rental/Contracts/elements/ContractStatuses.vue'
import StockStatuses from '@/views/Rental/Contracts/elements/StockStatuses.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import UploadBondFile from '@/views/Rental/Contracts/elements/contractBondFile.vue'

export default {
  name: 'ContractForm',
  components: {
    UploadBondFile,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    StockSearch,
    SelectedStocks,
    RentalStock,
    ContractNo,
    StartDate,
    EndDate,
    ContractDate,
    ContractFile,
    CustomerFiles,
    Notes,
    ContractStatuses,
    StockStatuses,
    SaveButton,
    ValidationObserver,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
    saveStatus() {
      return this.$store.getters['rentalContracts/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      if (!this.isUpdate) {
        this.$router.push('/rental/contracts')
      }
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.isUpdate) {
            this.dataItem.id_com_customer = this.$route.params.id
          }
          this.$store.dispatch('rentalContracts/saveData', this.dataItem)
        }
      })
    },
  },
}
</script>
