<template>
  <div>
    <b-form-group
      label="Bono Evrakları"
      label-for="upload_bond_file"
    >
      <b-form-file
        v-model="dataItem.upload_bond_files"
        placeholder="Bir dosya seçin veya buraya bırakın..."
        drop-placeholder="Dosya buraya bırakın..."
        browse-text="Dosya Seçin"
        multiple
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormFile,
} from 'bootstrap-vue'

export default {
  name: 'UploadBondFile',
  components: {
    BFormGroup,
    BFormFile,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
  },
}
</script>
